<template>
  <footer class="h-footer-size px-10 left-0 z-100 w-full">
    <div class="flex flex-row items-end justify-end">
      <label class="text-graytext text-sm lg:text-base mr-3">
        Powered by Redex Copyright © {{ new Date().getFullYear() }}. All rights
        reserved.
      </label>
      <img
        class="h-8"
        :src="require('@/assets/images/logo.png')"
        alt="t-recs logo"
      />
    </div>
  </footer>
</template>

<style lang="css" scoped></style>
